<script lang="ts" setup>
  import { format } from 'date-fns'
  // @ts-ignore
  import { es, fr, it, de } from 'date-fns/locale/index.js'
  import { latamCountries } from '@/constants/'

  // Scroll Store
  const scroll: any = useScrollStore()
  const { isScrolledInput } = storeToRefs(scroll) as { isScrolledInput: boolean }
  if(process.client){
    window.addEventListener('scroll', scroll.checkScrollInput)
  }

  // Currency Store
  const currencyStore = useCurrencyStore()
  const { currency } = storeToRefs(currencyStore)

  // Idioma
  const { locale, t } = useI18n()

  // Router
  const router = useRouter()
  const route = useRoute()

  // Config
  const runtimeConfig = useRuntimeConfig()

  // Strapi
  const errors: any = useState('errorMesages')
  
  // Home Store
  const isHome = useState('isHome')

  // Ubicacion
  const ubicacion: any = useState('ubicacion', () => '')

  const commandPaletteRef = ref()
  const commandPaletteRef2 = ref()

  function onSelectCommand (option: any) {
    setTimeout(() => {
      commandPaletteRef.value.comboboxApi.inputRef.value = option?.label ?? ''
      if(commandPaletteRef2?.value) commandPaletteRef2.value.comboboxApi.inputRef.value = option.label
      selectedUbicacion.value = option
      ubicacion.value = option?.label ?? ''
    }, 300)
  }

  function onClickCommand () {
    commandPaletteRef.value.comboboxApi.inputRef.value = ''
    if(commandPaletteRef2?.value) commandPaletteRef2.value.comboboxApi.inputRef.value = ''
    selectedUbicacion.value = null
  }

  var selectedUbicacion: any = useState('selectedUbicacion', () => useCookie('destination'))
  var destinationCookie: any = useCookie('destination', { maxAge: 86400 }) // cookie destino, expira en 24 horas

  const labelUbicacion = computed(() => route.query?.['data-search'] ?? destinationCookie.value?.name_translated ?? '')
  watch(labelUbicacion, (newValue) => {
    if(commandPaletteRef.value?.comboboxApi?.inputRef) commandPaletteRef.value.comboboxApi.inputRef.value = newValue ?? '' // cambiar texto del input
  })

  watch(selectedUbicacion, (newResultado: any) => {

    if(commandPaletteRef.value?.comboboxApi?.inputRef) commandPaletteRef.value.comboboxApi.inputRef.value = newResultado?.name_translated ?? '' // cambiar texto del input

    if(selectedUbicacion.value){
      destinationCookie.value = selectedUbicacion.value
    }
    
    if(newResultado?.num_hotels == 0){
      addDataLayer({
        event: 'BusquedaSinHotelesEvent',
        BusquedaSinHotelesEvent: newResultado?.name_general
      })
    }
  })

  var latitude: Number | null = null
  var longitude: Number | null = null

  // GeoIP
  const getDestination = (geoIpData: any) => { // obtener info destino con geoip
    latitude = geoIpData?.location?.latitude
    longitude = geoIpData?.location?.longitude
    useFetch(`${runtimeConfig.public.API_CLIENT_BASE_URL}/destination`, {
      headers: { "Content-Type": "application/json" },
      query: { 
        latitude,
        longitude,
        locale
      },
      server: false,
      onResponse({ response }) {
        const destinationInfo = response?._data?.data[0]
        destinationCookie.value = destinationInfo
        
        // Moneda
        const country = destinationInfo?.country_id // código país
        const updateCurrency = async () => {
          currency.value = await getCurrencyByCountry(country) // obtener y cambiar moneda al país
        }
        updateCurrency()
        updateInfoUbicacion(destinationInfo) // actualizar info ubicación
      }
    })
  }

  const updateInfoUbicacion = (destination: any) => { // actualizar info input y seleccion
    if(commandPaletteRef.value?.comboboxApi?.inputRef) commandPaletteRef.value.comboboxApi.inputRef.value = destination?.name_translated ?? ''
    selectedUbicacion.value = destination ?? {} // seleccion de ubicación
    ubicacion.value = destination?.name_translated ?? {} 
  }

  watch(() => [commandPaletteRef.value?.comboboxApi?.inputRef, commandPaletteRef2.value?.comboboxApi?.inputRef], async ([newVal1, newVal2]) => {
    await nextTick()
    if (newVal1) {
      newVal1.value = destinationCookie.value?.name_translated ?? ''
    }
    if (newVal2) {
      newVal2.value = destinationCookie.value?.name_translated ?? ''
    }
  }, { immediate: true })

  onMounted(async () => {
    if (!destinationCookie.value) {
      const miIp = await $fetch('https://api.ipify.org', { server: false, timeout: 2000 }).catch(() => null)
      if (miIp) {
        const geoIpData = await $fetch('/geoip', {
          method: 'post',
          body: { miIp },
          server: false,
          onResponseError({ response }) {
            console.error('Error /geoip', response)
          }
        }).catch(() => null)
        if (geoIpData) {
          getDestination(geoIpData)
        }
      }
    } else {
      updateInfoUbicacion(destinationCookie.value)
    }
  })

  // Input search
  var colorInput = ref('text-gray-400')
  if (ubicacion.value) {
    const { data: listaResultados }: any = await useLazyFetch(runtimeConfig.public.API_CLIENT_BASE_URL + '/autocomplete', {
      headers: { "Content-Type": "application/json" },
      query: { 
        term: ubicacion,
        limit: 10,
        locale
      },
      onResponse() {
        colorInput.value = 'text-gray-400' // color input
      },
      server: false
    })
    const error_resultados = ref(false)
    watch(listaResultados, (newLista) => { // detectar cuándo no haya resultados
      if (ubicacion.value?.length > 2 && newLista.data.length == 0) {
        addDataLayer({
          event: 'BusquedaSinDestinoEvent',
          BusquedaSinDestinoEvent: ubicacion?.value
        })
        error_resultados.value = true
      } else {
        error_resultados.value = false
      }
    })
  }
  
  // Fecha
  const datepicker: any = ref(null)
  const hoy = new Date()
  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)
  
  var selectedDia = useState('selectedDia', () => new Date())
  const dateSelected = new Date(selectedDia.value)
  var selectedDiaCookie: Ref<Date> = useCookie('selectedDia', { expires: new Date(new Date().setHours(23, 59, 59, 999)) }) // cookie fecha, expira al final del día
    
  // Si el país es de LATAM, el cambio de hora es a las 18:00, si es España, a las 15:00, si es otro, a las 12:00
  const cambioHora = latamCountries.includes(selectedUbicacion.value?.country_id) ? 18 : selectedUbicacion.value?.country_id === 'ES' ? 15 : 12
  const currentHour = new Date().getHours()
  if (currentHour >= cambioHora && !selectedDiaCookie.value) {
    selectedDia.value = tomorrow
  }

  if(selectedDiaCookie.value){
    selectedDia.value = selectedDiaCookie.value
  }

  if(route.query?.['data-checkinDate']){
    selectedDia.value = getCookieDate(String(route.query?.['data-checkinDate']))
  }

  watch(selectedDia, (newValue) => {
    selectedDiaCookie.value = newValue
  })

  const selectHoy = () => {
    selectedDia.value = hoy
    datepicker.value?.closeMenu()
  }
  const selectMañana = () => {
    selectedDia.value = tomorrow
    datepicker.value?.closeMenu()
  }
  
  // Si la fecha es menor a la de hoy, seleccionar hoy
  if (dateSelected.setHours(0, 0, 0, 0) < hoy.setHours(0, 0, 0, 0)) {
    selectHoy()
  }
  
  const locales: any = { es, fr, it, de }
  const formatDatePicker = (date: any) => {
    date = new Date(date)
    const day = format(date, 'dd', { locale: locales[locale.value] })
    const month = format(date, 'MMMM', { locale: locales[locale.value] })
    if (locale.value === 'es') {
      return `${day} de ${month}`
    } else if (locale.value === 'it' || locale.value === 'fr' || locale.value === 'de') {
      return `${day} ${month}`
    } else {
      return `${month} ${day}`
    }
  }

  // Click search
  const isOpenSearch = ref(false)

  const disableSearch = ref(false)
  const buscar = async () => {
    if(ubicacion.value && !objIsEmpty(selectedUbicacion.value)){
      isOpenSearch.value = false
      const regexHotel = /(?<=\/[a-z]{2}\/)[^\/]+(?=\.html)/
      const matchHotel = selectedUbicacion?.value?.['url'].match(regexHotel)
      const slug = matchHotel ? matchHotel[0] : ''
      const hotelPath = `/${locale.value}/${slug}.html`
      let path = destinationCookie.value?.category_id == 1 ? hotelPath : `/${locale.value}/search` // si es hotel, va a landing hotel
      disableSearch.value = true
      const [latitude, longitude] = (destinationCookie.value?.location || '').split(',')

      await navigateTo({
        path,
        query: {
          'data-destinationId': destinationCookie.value?.category_id === 3 ? '' : destinationCookie.value?.element_id,
          'data-longitude': longitude,
          'data-latitude': latitude,
          'data-distance': destinationCookie.value?.search_distance,
          'data-search': destinationCookie.value?.name_translated,
          'data-country': destinationCookie.value?.country_name_translated,
          'search': destinationCookie.value?.name_translated,
          'data-checkinDate': formatDateGuion(selectedDia.value),
          'GFakeDate': formatFakeDate(selectedDia.value),
          'curr': currency.value
        }
      })
    }else{
      colorInput.value = 'text-red-500'
    }
  }

  // Buscador
  const groups = [{
    key: 'destinations',
    label: (q: any) => q && `${ t('labels.searchDestino') } “${q}”...`,
    search: async (q: any) => {
      colorInput.value = 'text-gray-400'
      if (!q) {
        return []
      }

      if (q.length > 2) {
        const { data: destinations }: any = await useLazyFetch(runtimeConfig.public.API_CLIENT_BASE_URL + '/autocomplete', {
          headers: { "Content-Type": "application/json" },
          query: { 
            term: q,
            limit: 10,
            locale
          },
          server: false
        })
        return destinations?.value?.data.map((destination: any) => (
          { 
            id: destination?.element_id, 
            label: destination?.name_translated, 
            suffix: destination?.country_name_translated,
            ...destination
          })
        )
      }
    }
  }]

</script>

<template>
  <!-- Hero Search Input -->
  <div v-if="!isScrolledInput && isHome">
    <div :class="`relative flex flex-col md:flex-row md:w-10/12 lg:w-7/12`">
      <div :class="`w-full flex flex-col md:flex-row gap-3 md:gap-2 md:bg-white shadow-sm rounded-full items-center p-1`">
        <ClientOnly>
          
          <!-- Ubicacion -->
          <div class="w-full md:w-6/12 relative h-2/3 md:border-r-2">
            <UCommandPalette
              ref="commandPaletteRef"
              @click="onClickCommand"
              @update:model-value="onSelectCommand"
              icon="i-heroicons-map-pin"
              :close-button="{ 
                icon: 'i-heroicons-x-mark-20-solid',
                color: 'gray',
                variant: 'link',
                padded: false 
              }"
              :ui="{
                wrapper: 'w-full h-full divide-y-0',
                input: {
                  wrapper: 'h-10 md:h-full',
                  height: 'h-full',
                  base: 'bg-white-500 rounded-full md:rounded-l-full md:rounded-r-none',
                  size: 'text-sm',
                  icon: {
                    base: `${colorInput}`
                  }
                },
                group: {
                  command: {
                    inactive: 'hover:bg-gray-100',
                  }
                },
                container: 'bg-white shadow rounded-2xl absolute top-12 z-50 w-full truncate md:min-w-96',
                emptyState: {
                  wrapper: 'absolute shadow top-12 z-50 bg-white rounded-2xl sm:px-10 py-10 md:min-w-96'
                }
              }"
              :autoclear="true"
              :groups="groups"
              :autoselect="false"
              :nullable="true"
              :placeholder="t('labels.ubicacion')"
              :empty-state="commandPaletteRef?.query && commandPaletteRef?.query.length > 3 ? { icon: 'i-heroicons-magnifying-glass-20-solid', queryLabel: `${errors?.error_resultados}` } : null"
              command-attributes="suffix"
            >
              <template #destinations-icon="{ command }">
                <Icon size="1.1em" :name="command?.type === 'hotel' ? 'ic:outline-bed' : command?.type === 'destination' ? 'ic:twotone-business' : 'ic:twotone-my-location'" />
              </template>
            </UCommandPalette>
          </div>

          <!-- Calendario -->
          <div :class="`flex items-center w-full md:w-4/12 md:rounded-r-none py-2  bg-white rounded-full md:pr-2`">
            <VueDatePicker teleport-center auto-apply :clearable="false" ref="datepicker" class="rounded-full" :locale="locale" v-model="selectedDia" :enable-time-picker="false" :format="formatDatePicker" :min-date="new Date()">
              <template #action-extra="{}">
                <div class="flex justify-center gap-4 pb-4 px-6">
                  <UButton :ui="{ rounded: 'rounded-full' }" :aria-label="$t('labels.hoy')" block variant="soft" @click="selectHoy()" class="w-1/2">{{ $t('labels.hoy') }}</UButton>
                  <UButton :ui="{ rounded: 'rounded-full' }" :aria-label="$t('labels.mañana')" block variant="soft" @click="selectMañana()" class="w-1/2">{{ $t('labels.mañana') }}</UButton>
                </div>
              </template>
            </VueDatePicker>
          </div>

          <!-- Skeleton -->
          <template #fallback>
            <USkeleton class="hidden md:block w-10/12 h-10 rounded-full dark:ring-gray-600" />
            <div class="w-full md:hidden grid gap-3">
              <USkeleton class="w-full h-10 rounded-full ring-1 ring-gray-300 dark:ring-gray-600" />
              <USkeleton class="w-full h-10 rounded-full ring-1 ring-gray-300 dark:ring-gray-600" />
            </div>
          </template>

        </ClientOnly>

        <!-- Search -->
        <div :class="`w-full md:p-0 md:w-2/12 min-w-24 text-right`">
          <UButton
            :disabled="disableSearch"
            class="bg-gradient-to-r from-byhours-500 to-pink-500"
            block
            :aria-label="$t('buttons.buscar')"
            :ui="{ 
              rounded: 'rounded-full'
            }"
            @click="buscar()"
            size="lg"
            :label="$t('buttons.buscar')"
          >
            <template #leading>
              <UIcon v-if="!disableSearch" class="text-xl min-w-5" name="i-heroicons-magnifying-glass-solid" />
              <svg v-else class="animate-spin h-5 min-w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </template>
          </UButton>
        </div>
      </div>
    </div>
  </div>

  <!-- Header Input md -->
  <div v-else :class="`w-full sm:w-[450px]`">
    <div class="hidden sm:flex">
      <div class="w-full flex gap-2 border bg-white shadow-sm rounded-full items-center p-0.5">
        <ClientOnly>

          <!-- Ubicacion -->
          <div class="w-8/12 relative h-2/3 md:border-r-2">
            <UCommandPalette
              ref="commandPaletteRef"
              @click="onClickCommand"
              @update:model-value="onSelectCommand"
              icon="i-heroicons-map-pin"
              :close-button="{ 
                icon: 'i-heroicons-x-mark-20-solid',
                color: 'gray',
                variant: 'link',
                padded: false 
              }"
              :ui="{
                wrapper: 'w-full h-full divide-y-0',
                input: {
                  wrapper: 'h-10 sm:h-full',
                  height: 'h-full',
                  base: 'bg-white-500 rounded-full sm:rounded-l-full sm:rounded-r-none',
                  size: 'text-sm',
                  icon: {
                    base: `${colorInput}`
                  }
                },
                group: {
                  command: {
                    inactive: 'hover:bg-gray-100',
                  }
                },
                container: 'bg-white shadow rounded-2xl absolute top-10 z-50 min-w-96 truncate',
                emptyState: {
                  wrapper: 'absolute shadow top-10 z-50 bg-white rounded-2xl sm:px-10 py-10 min-w-96'
                }
              }"
              :autoclear="true"
              :groups="groups"
              :autoselect="false"
              :nullable="true"
              :placeholder="t('labels.ubicacion')"
              :empty-state="commandPaletteRef?.query && commandPaletteRef?.query.length > 3 ? { icon: 'i-heroicons-magnifying-glass-20-solid', queryLabel: `${errors?.error_resultados}` } : null"
              command-attributes="suffix"
            >
              <template #destinations-icon="{ command }">
                <Icon size="1.1em" :name="command?.type === 'hotel' ? 'ic:outline-bed' : command?.type === 'destination' ? 'ic:twotone-business' : 'ic:twotone-my-location'" />
              </template>
            </UCommandPalette>
          </div>

          <!-- Calendario -->
          <div :class="`flex items-center w-5/12 sm:rounded-r-none rounded-full dark:border-gray-700 sm:pr-2 ring-1 sm:ring-0 ring-gray-200 dark:ring-gray-700`">
            <VueDatePicker :teleport-center="false" auto-apply :clearable="false" ref="datepicker" class="rounded-full" :locale="locale" cancelText="Cancelar" selectText="Seleccionar" v-model="selectedDia" :enable-time-picker="false" :format="formatDatePicker" :min-date="new Date()">
              <template #action-extra="{}">
                <div class="flex justify-center gap-4 pb-4 px-6">
                  <UButton :ui="{ rounded: 'rounded-full' }" :aria-label="$t('labels.hoy')" block variant="soft" @click="selectHoy()" class="w-1/2">{{ $t('labels.hoy') }}</UButton>
                  <UButton :ui="{ rounded: 'rounded-full' }" :aria-label="$t('labels.mañana')" block variant="soft" @click="selectMañana()" class="w-1/2">{{ $t('labels.mañana') }}</UButton>
                </div>
              </template>
            </VueDatePicker>
          </div>

          <!-- Skeleton -->
          <template #fallback>
            <div class="w-[400px]">
              <USkeleton class="w-full h-9 rounded-full" />
            </div>
          </template>
        </ClientOnly>

        <!-- Search -->
        <div :class="`p-0 w-1/12 text-right`">
          <UButton
            class="bg-gradient-to-r from-byhours-500 to-pink-500"
            block
            :aria-label="$t('buttons.buscar')"
            :ui="{ 
              rounded: 'rounded-full'
            }"
            @click="buscar()"
            icon="i-heroicons-magnifying-glass-solid"
            size="md"
          />
        </div>
      </div>
    </div>
  </div>
  
  <!-- Header Button -->
  <div class="sm:hidden w-full sm:w-[500px]">
    <UButton class="min-w-52 min-h-10 justify-center border" @click="isOpenSearch = true" size="md" variant="soft" color="gray" :ui="{ rounded: 'rounded-full' }">
      <ClientOnly>
        <span class="truncate max-w-20">{{ ubicacion ? ubicacion : '🗺️' }} </span> | <span class="truncate max-w-20">{{ formatDatePicker(selectedDia) }}</span>
      </ClientOnly>
    </UButton>
    <USlideover v-model="isOpenSearch" prevent-close>
      <UCard class="flex flex-col flex-1" :ui="{ body: { base: 'flex-1' }, ring: '', divide: 'divide-y divide-gray-100 dark:divide-gray-800' }">
        <template #header>
          <div class="flex items-center justify-between">
            <h3 class="text-base leading-6 text-gray-900 dark:text-white">
              {{ $t('buttons.buscar') }} 
            </h3>
            <UButton color="gray" variant="ghost" icon="i-heroicons-x-mark-20-solid" class="-my-1" @click="isOpenSearch = false" />
          </div>
        </template>

        <div class="flex gap-4 flex-col">
          
          <!-- Ubicación -->
          <div class="relative">
            <UCommandPalette
              ref="commandPaletteRef2"
              @click="onClickCommand"
              @update:model-value="onSelectCommand"
              icon="i-heroicons-map-pin"
              :close-button="{ 
                icon: 'i-heroicons-x-mark-20-solid',
                color: 'gray',
                variant: 'link',
                padded: false 
              }"
              :ui="{
                wrapper: 'w-full h-full divide-y-0 ring-1 ring-gray-300 rounded-full',
                input: {
                  wrapper: 'h-10 md:h-full',
                  height: 'h-full',
                  base: 'bg-white-500 rounded-full md:rounded-l-full md:rounded-r-none',
                  size: 'text-sm',
                  icon: {
                    base: `${colorInput}`
                  }
                },
                group: {
                  command: {
                    inactive: 'hover:bg-gray-100',
                  }
                },
                container: 'bg-white shadow rounded-2xl absolute top-12 z-50 w-full truncate ring-1 ring-gray-300',
                emptyState: {
                  wrapper: 'absolute shadow top-12 z-50 bg-white rounded-2xl md:px-10 py-10 w-full'
                }
              }"
              :autoclear="true"
              :groups="groups"
              :autoselect="false"
              :nullable="true"
              :placeholder="t('labels.ubicacion')"
              :empty-state="null"
              command-attributes="suffix"
            >
              <template #destinations-icon="{ command }">
                <Icon size="1.1em" :name="command?.type === 'hotel' ? 'ic:outline-bed' : command?.type === 'destination' ? 'ic:twotone-business' : 'ic:twotone-my-location'" />
              </template>
            </UCommandPalette>

          </div>

          <!-- Calendario -->
          <div :class="`flex items-center w-full rounded-full ring-1 ring-gray-300`">
            <VueDatePicker :teleport-center="false" auto-apply :clearable="false" ref="datepicker" class="rounded-full py-2" :locale="locale" cancelText="Cancelar" selectText="Seleccionar" v-model="selectedDia" :enable-time-picker="false" :format="formatDatePicker" :min-date="new Date()">
              <template #action-extra="{}">
                <div class="flex justify-center gap-4 pb-4 px-6">
                  <UButton :ui="{ rounded: 'rounded-full' }" :aria-label="$t('labels.hoy')" block variant="soft" @click="selectHoy()" class="w-1/2">{{ $t('labels.hoy') }}</UButton>
                  <UButton :ui="{ rounded: 'rounded-full' }" :aria-label="$t('labels.mañana')" block variant="soft" @click="selectMañana()" class="w-1/2">{{ $t('labels.mañana') }}</UButton>
                </div>
              </template>
            </VueDatePicker>
          </div>

          <!-- Search -->
          <div>
            <UButton
              class="bg-gradient-to-r from-byhours-500 to-pink-500"
              block
              :aria-label="$t('buttons.buscar')"
              :ui="{ 
                rounded: 'rounded-full'
              }"
              @click="buscar()"
              icon="i-heroicons-magnifying-glass-solid"
              size="lg"
              :label="$t('buttons.buscar')"
            />
          </div>
        </div>
      </UCard>
    </USlideover>
  </div>

</template>

<style lang="scss">
  // Datepicker
  :root{
    --dp-font-family: 'NHaasGroteskText';
    --dp-border-radius: 1rem; /*Configurable border-radius*/
    --dp-cell-border-radius: 9999px; /*Specific border radius for the calendar cell*/
    --dp-common-transition: all 0.1s ease-in; /*Generic transition applied on buttons and calendar cells*/
    /*Sizing*/
    --dp-month-year-row-height: 35px; /*Height of the month-year select row*/
    --dp-month-year-row-button-size: 35px; /*Specific height for the next/previous buttons*/
    --dp-button-icon-height: 20px; /*Icon sizing in buttons*/
    --dp-cell-size: 5px; /*Width and height of calendar cell*/
    --dp-cell-padding: 18px; /*Padding in the cell*/
    --dp-input-icon-padding: 2.25rem; /*Padding on the left side of the input if icon is present*/
    --dp-input-padding: 0rem; /*Padding in the input*/
    --dp-menu-min-width: 325px; /*Adjust the min width of the menu*/
    --dp-action-buttons-padding: 15px 30px; /*Adjust padding for the action buttons in action row*/
    --dp-row-margin: 5px 0; /*Adjust the spacing between rows in the calendar*/
    --dp-calendar-header-cell-padding:  0.5rem; /*Adjust padding in calendar header cells*/
    --dp-menu-padding: 15px 15px; /*Menu padding*/
    
    /*Font sizes*/
    --dp-font-size:  0.875rem; /*Default font-size*/
    --dp-preview-font-size: 0.8rem; /*Font size of the date preview in the action row*/
    --dp-time-font-size: 0.8rem; /*Font size in the time picker*/
    
    /*Transitions*/
    --dp-animation-duration: 0.1s; /*Transition duration*/
    --dp-menu-appear-transition-timing: cubic-bezier(.4, 0, 1, 1); /*Timing on menu appear animation*/
    --dp-transition-timing: ease-out; /*Timing on slide animations*/
  }

  // Mobile
  @media (max-width: 768px){
    :root{
      --dp-menu-min-width: 350px;
    }
    .dp__input_icons {
      padding: 6px 19px !important;
    }
    .dp__input{
      padding-inline-start: 2.5rem !important;
    }
  }

  .dp__input_icons {
    padding: 6px 15px;
  }

  .dp__input{
    padding-inline-start: 2.25rem;
    border-radius: 9999px;
  }

  .dp__theme_light, .dp__theme_dark {
    --dp-primary-color: #ff620a;
  }
  .dp__theme_light {
    --dp-border-color: transparent;
    --dp-border-color-hover: transparent;
    --dp-icon-color: rgb(156 163 175);
  }
  .dp__theme_dark {
    --dp-background-color: rgb(31 41 55);
    --dp-border-color: transparent;
    --dp-border-color-hover: transparent;
    --dp-icon-color: rgb(107 114 128);
    --dp-hover-color: rgb(15 23 42);
    --dp-disabled-color: rgb(55 65 81);
  }
  
</style>