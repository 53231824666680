// Input header
export const useScrollStore = defineStore('scroll', () => {
  const isScrolledInput = ref(false)

  function checkScrollInput() {
    const height = window.innerHeight - (window.innerHeight / 2)
    isScrolledInput.value = window.scrollY > (height + 25)
  }

  return { isScrolledInput, checkScrollInput }
})